import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  ModalCloseButton,
  Box,
  Text,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import { ethers } from "ethers";
import FishGame from "../../utils/FishGame";
import btn from "../../assets/bottons.png";

export default function Index() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const [tokenId, setTokenId] = useState(null);
  const [error, setError] = useState(null);
  const toast = useToast();

  const getContract = async () => {
    try {
      const contract = await FishGame();
      return contract;
    } catch (error) {
      console.log("Get Contract Error: ", error);
    }
  };

  const handleClick = async () => {
    if (typeof window.ethereum !== "undefined") {
      setLoading(true);
      const contract = await getContract();
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const accounts = await provider.listAccounts();
      const add = accounts[0];
      if (add === undefined) {
        toast({
          title: "Please Connect to Metamask",
          description: "Your Wallet is Not Connected",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      const Wcontract = contract.connect(signer);
      const network = await provider.getNetwork();
      const chainId = network.chainId;
      if (chainId !== 1) {
        toast({
          title: "Wrong Network",
          description: "Please Switch to Ethereum Mainnet",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      if (tokenId == null) {
        toast({
          title: "Token ID Error.",
          description: "There is some issue with tokenId",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      try {
        const data = await Wcontract.bonusClaim(tokenId);
        console.log(data);
        setLoading(false);
        toast({
          title: "Transaction Successful",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      } catch (error) {
        const errorMessage = error.message;
        console.log(errorMessage);
        const err1 = errorMessage.includes("Bonus already claimed");
        const err2 = errorMessage.includes(
          "execution reverted: Bonus $WORM cannot be claimed"
        );
        if (err1 === true) {
          toast({
            title: "Bonus already claimed",
            description: "You have already claimed your bonus",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
          return;
        }
        if (err2 === true) {
          toast({
            title: "Cannot Claim $Worm",
            description: "Sorry you cannot do one time Worm Claim",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
          return;
        }
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getContract();
  }, []);

  return (
    <>
      <Button
        size="lg"
        w="20vw"
        backgroundColor="transparent"
        backgroundImage={btn}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
        _hover={{
          backgroundImage: btn,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          color: "white",
        }}
        _active={{
          backgroundImage: btn,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          color: "white",
        }}
        onClick={onOpen}
        color="white"
      >
        Claim Bonus
      </Button>
      <Modal
        blockScrollOnMount={false}
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Claim Bonus</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={10}>
            <VStack spacing={3}>
              <Text>
                Please Enter your FisherMan Token Id and Claim your One time
                Bonus
              </Text>
              <Input
                type="number"
                onChange={(e) => setTokenId(e.target.value)}
              />
              <Button
                w="full"
                colorScheme="orange"
                isLoading={loading}
                onClick={handleClick}
              >
                Claim Bonus
              </Button>
              <Text>{error}</Text>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
