import { ethers } from "ethers";
import { FISH_GAME } from "../contracts/index";
import ABI from "../abis/FISH_GAME.json";
export default async function FisherMan() {
  if (typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(FISH_GAME, ABI, signer);
    try {
      const data = await contract.owner();
      console.log("CONNECTED TO FISH GAME CONTRACT");
    } catch (err) {
      console.log("Error: ", err);
    }

    return contract;
  }
}
