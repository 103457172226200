import { ethers } from "ethers";
import { GOOD_FISH } from "../contracts/index";
import ABI from "../abis/GOOD_FISH.json";
export default async function FisherMan() {
  if (typeof window.ethereum !== "undefined") {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(GOOD_FISH, ABI, signer);
    try {
      const data = await contract.owner();
      console.log("data: ", data);
    } catch (err) {
      console.log("Error: ", err);
    }

    return contract;
  }
}
